/*=================================================================
  Navigation
==================================================================*/
.navigation {
  transition: .3s all;

  .logo-default {
    display: none;
  }

  .dropdown-toggle {
    &::before,
    &::after {
      display: none;
    }
    i {
      font-size: 10px;
      margin-left: 2px;
      vertical-align: 2px;
    }
  }

  .navbar-light .navbar-nav {
    .nav-item {
      .nav-link {
        color: $light;
        font-size: 14px;
        line-height: 26px;
        padding: 20px 15px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transition: .2s ease-in-out 0s;

        &:hover,
        &:active,
        &:focus {
          background: none;
          color: $primary-color;
        }
      }

      &.active {
        .nav-link {
          color: $primary-color;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .nav-link {
      padding: 6px !important;
    }
    .navbar-collapse.show {
      overflow-y: auto;
      max-height: calc(100vh - 55px);
    }
  }
  .dropdown-submenu .dropdown-menu {
    margin: 0;
    background-color: #131313;
  }

  @media (min-width: 992px) {
    .dropdown-submenu .dropdown-menu {
      left: 100%;
      top: -5px;
    }
    .dropleft .dropdown-menu {
      left: auto;
      right: 100%;
      margin: 0;
      top: -5px;
    }
    .dropdown-submenu .dropdown-menu {
      background-color: #000;
    }
  }

  .dropdown:hover > .dropdown-menu {
    visibility: visible;
    opacity: 1;
  }

  .dropdown-menu {
    border: 0;
    border-radius: 0;
    top: 80%;
    left: 5px;
    display: block;
    visibility: hidden;
    opacity: 0;
    padding: 0;
    background: #000;
    transition: .3s ease;

    li:first-child {margin-top: 12px}
    li:last-child {margin-bottom: 12px}
  }

  @media (max-width: 991px) {
    .dropdown-menu {
      display: none;
      opacity: 1;
      visibility: visible;
      text-align: center;
      transition: 0s;
    }
  }

  @media (max-width: 991px) {
    .dropdown-menu.show {
      display: block;
    }
    .dropdown-menu {
      float: left;
      width: 100%;
    }
  }

  .dropdown-item {
    color: rgba($color: $light, $alpha: .8);
    transition: .2s ease;
    padding: 8px 22px;

    &:focus,
    &:hover {
      color: $light;
      background: transparent;
    }
  }
}

@media (max-width: 991px) {
  .navigation {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.06);
    background: $light;

    .navbar-light .navbar-nav {
      .nav-item {
        .nav-link {
          color: $black;
        }
      }
    }

    .logo-white {
      display: none;
    }

    .logo-default {
      display: block;
    }

    .navbar-light .navbar-toggler {
      color: rgba(#000, .5);
      border-color: rgba(#000, .1);
    }
  }
}

.sticky-header {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.06);
  background: $light;

  .navbar-light .navbar-nav {
    .nav-item {
      .nav-link {
        color: $black;
      }
    }
  }

  .logo-white {
    display: none;
  }

  .logo-default {
    display: block;
  }
}

.navbar-brand {
  height: auto;
  padding: 8px 0;
}

.navbar-brand h1 {
  margin: 0;
}