
/*=================================================================
  Footer section
==================================================================*/
.top-footer {
  background-color: #222222;
  border-top: 1px solid #404040;
  padding: 100px 0 80px;
  h3 {
    font-size: 16px;
    line-height: 1.5;
    color: $light;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 20px;
  }
  p {
    font-size: 13px;
    line-height: 25px;
    color: #bdbdbd;
    font-weight: 300;
    letter-spacing: 1px;
    padding-right: 20px;
  }
  li a {
    font-size: 13px;
    line-height: 30px;
    color: #bdbdbd;
    font-weight: 300;
    letter-spacing: 1px;
    text-transform: capitalize;
    transition: color .3s;
    font-family: $primary-font;
    display: block;
    &:hover {
      color: $primary-color;
    }
  }
}

.footer-bottom {
  text-align: center;
  background-color: #1b1b1b;
  border-top: 1px solid #2c2c2c;
  padding: 30px 0;
  h5 {
    font-size: 12px;
    line-height: 1;
    color: #888888;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 7px;
    margin-bottom: 3px;
  }
  h6 {
    font-size: 11px;
    line-height: 15px;
    color: #888888;
    font-weight: 400;
    text-transform: capitalize;
    letter-spacing: 2px;
    margin-bottom: 0;
  }
  a {
    color: $primary-color;
  }
}