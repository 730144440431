/*
THEME: Bingo | Responsive Multipurpose Parallax HTML5 Template
VERSION: 1.0.0
AUTHOR: Themefisher

HOMEPAGE: https://themefisher.com/products/bingo-bootstrap-business-template/
DEMO: https://demo.themefisher.com/themefisher/bingo/
GITHUB: https://github.com/themefisher/Bingo-Bootstrap-Business-Template

WEBSITE: https://themefisher.com
TWITTER: https://twitter.com/themefisher
FACEBOOK: https://www.facebook.com/themefisher
*/

@import 'variables.scss';
@import 'media-queries.scss';
@import 'typography.scss';
@import 'common.scss';
@import 'templates/header.scss';
@import 'templates/navigation.scss';
@import 'templates/hero-area.scss';
@import 'templates/about_us.scss';
@import 'templates/call-to-action.scss';
@import 'templates/contact.scss';
@import 'templates/counter.scss';
@import 'templates/pricing.scss';
@import 'templates/portfolio.scss';
@import 'templates/services.scss';
@import 'templates/skills.scss';
@import 'templates/team.scss';
@import 'templates/testimonials.scss';
@import 'templates/blog.scss';
@import 'templates/single-post.scss';
@import 'templates/footer.scss';
@import 'templates/404.scss';
@import 'responsive.scss';





